import { trainLineApi } from '@index/apis';
import Banner from './Banner';
import Steps from './steps/Steps';
import StepItem from './steps/StepItem';
import EeTrackMap from '@/components/track-map';
import EeTrainLineSelect from '@/components/train-line-select.vue';

export default {
  install(app) {
    app.component('Banner', Banner);
    app.component('Steps', Steps);
    app.component('StepItem', StepItem);
    app.component('EeTrackMap', EeTrackMap);
    EeTrainLineSelect.props.remoteMethod.default = keyword => trainLineApi.keywordQuery(keyword);
    app.component(EeTrainLineSelect.name, EeTrainLineSelect);
  }
};
