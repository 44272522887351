import { defineComponent } from 'vue';
import AppHeader from './AppHeader.vue';
import AppMain from './AppMain.vue';
import AppFooter from './AppFooter.vue';
export default defineComponent({
  name: 'App',
  components: {
    AppHeader,
    AppMain,
    AppFooter
  },

  data() {
    return {
      navs: [{
        label: '首页',
        path: '/',
        type: 'route'
      }, {
        label: '新闻中心',
        path: '/news',
        type: 'route'
      }, // {
      //   label: '业务展示',
      //   path: '/business'
      // },
      {
        label: '信息查询',
        path: '/help',
        type: 'route'
      }, {
        label: '党的建设',
        path: 'https://www.cdacdg.com/news/30/',
        type: 'link'
      }, {
        label: '关于企业',
        path: '/about',
        type: 'route'
      }]
    };
  }

});