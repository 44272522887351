import { createApp } from 'vue';
import router from './router';
import store from './store';
import http from '@/libs/http';
import components from './components';
import elementPlus from 'element-plus';
import locale from 'element-plus/es/locale/lang/zh-cn';
import '@/libs/baidu-map';
import 'element-plus/theme-chalk/index.css';
import './assets/css/style.scss';
import App from './App.vue';

createApp(App)
  .use(store)
  .use(router)
  .use(elementPlus, { locale })
  .use(components)
  .use(app => {
    app.config.globalProperties.$http = http;
    app.config.globalProperties.$console = console;
    app.config.unwrapInjectedRef = true;
  })
  .mount('#app');
