import { defineComponent } from 'vue';
import { customerApi } from '@index/apis';
export default defineComponent({
  name: 'AppHeader',
  props: {
    navs: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      isLogined: false
    };
  },

  computed: {
    location() {
      return location;
    }

  },

  mounted() {
    customerApi.current().then(current => {
      this.isLogined = !!current;
    });
  },

  methods: {
    handleNavClick(nav) {
      if (nav.type == 'route') {
        this.$router.push(nav.path);
      } else if (nav.type == 'link') {
        window.open(nav.path);
      } else {
        this.$router.push('/');
      }
    }

  }
});