import { createStore } from 'vuex';

export default createStore({
  state: {
    isLogined: false,
    currentUser: undefined,
    setting: {}
  },
  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
      state.isLogined = !!currentUser;
    },
    setSetting(state, setting) {
      state.setting = setting;
    }
  }
});
