import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "steps"
};
const _hoisted_2 = {
  key: 0,
  class: "steps-border-line"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return _openBlock(), _createBlock(_resolveDynamicComponent(item), {
      index: index
    }, null, 8, ["index"]);
  }), 256))]), _ctx.showBorderLine ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("", true)]);
}