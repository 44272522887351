import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AppFooter',
  props: {
    navs: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleNavClick(event, nav) {
      if (nav.type == 'route') {
        this.$router.push(nav.path).then(() => {
          document.getElementById('app').scrollTop = 0;
        });
      } else if (nav.type == 'link') {
        window.open(nav.path);
      } else {
        this.$router.push('/');
      }
    }

  }
});