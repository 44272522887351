import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "index/home" */ './views/Home.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "index/news" */ './views/News.vue')
  },
  {
    path: '/news/detail/:id',
    component: () => import(/* webpackChunkName: "index/news_detail" */ './views/NewsDetail.vue'),
    props: true,
    meta: {
      nav: '/news'
    }
  },
  {
    path: '/news/search/result',
    component: () => import(/* webpackChunkName: "index/news_search_result" */ './views/NewsSearchResult.vue'),
    props: route => route.query,
    meta: {
      nav: '/news'
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "index/help" */ './views/Help.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "index/about" */ './views/About.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
