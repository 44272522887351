import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'StepItem',
  inject: ['active'],
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    color() {
      return this.active >= this.index ? '#333333' : '#cccccc';
    }

  }
});

import { useCssVars as _useCssVars } from 'vue';

const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "879e4966": _ctx.color
  }));
};

const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;