import { computed, defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'Steps',
  props: {
    //设置当前激活步骤
    active: {
      type: Number,
      default: 0
    },
    //设置步骤高度
    height: {
      type: Number,
      default: 80
    },
    //设置是否显示底部线
    showBorderLine: {
      type: Boolean,
      default: false
    }
  },

  provide() {
    return {
      active: computed(() => this.active)
    };
  },

  computed: {
    items() {
      const defaultSlot = this.$slots.default;

      if (defaultSlot) {
        return defaultSlot().filter(({
          type
        }) => type.name == 'StepItem');
      }

      return [];
    },

    //css v-bind
    steps_height() {
      return `${this.height}px`;
    },

    step_item_border_width() {
      return `${this.height / 2}px`;
    },

    step_item_margin_left() {
      return `${8 - this.height / 2}px`;
    }

  }
});

import { useCssVars as _useCssVars } from 'vue';

const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "5fa78998": _ctx.steps_height,
    "47d2cc05": _ctx.step_item_border_width,
    "318b64b4": _ctx.step_item_margin_left
  }));
};

const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;