import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  key: 1,
  class: "banner-title"
};
const _hoisted_3 = {
  class: "banner-title__label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_container = _resolveComponent("el-container");

  return _openBlock(), _createBlock(_Teleport, {
    to: ".banner-wrap"
  }, [_createVNode(_component_el_container, {
    class: _normalizeClass(['banner', _ctx.$attrs.class]),
    style: _normalizeStyle(_ctx.$attrs.style)
  }, {
    default: _withCtx(() => [_ctx.icon ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: _ctx.icon,
      class: "banner-icon"
    }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _ctx.title ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.title) + " ", 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["class", "style"])]);
}